/* src/assets/fonts/fonts.css */

.inter-normal-400 {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .inter-bold-700 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .montserrat-normal-400 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .montserrat-bold-700 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  .tenor-sans-regular {
    font-family: "Tenor Sans", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto {
    font-family: "Roboto", serif;
    font-optical-sizing: auto;
    font-variation-settings:
      "wdth" 100;
  }
  
  /* .playwrite-us-trad{
    font-family: "Playwrite US Trad", serif;
    font-optical-sizing: auto;
    font-style: normal;
  } */

  .tenor-sans-regular {
    font-family: "Tenor Sans", serif;
    font-weight: 400;
    font-style: normal;
  }

  

.playwrite-us-trad {
  font-family: "Playwrite US Trad", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
  