.headerContainer{
    width: 100%;
    height:171px;  
    background: #FFEECF;
    position: relative;  
    cursor: pointer;
}



/* .categoryproduct:not(:hover) {
  opacity: 0; 
  pointer-events: none; 
} */


.coupanCodeRow{
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
}

/* :where(.css-dev-only-do-not-override-apn68).ant-btn-color-default {
 color:#343330 !important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
} */
.coupanCodeRow p{
    font-family: Tenor Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.38px;
    text-align: left;
    margin-bottom: 0%;
}
.coupancode {
    padding: 1px 5px;
    background-color: #FFA500;
    color: white;
    border-color: #FFA500;
  }
  
  .coupanCodeRow {
    position: relative;
  }
  
  .coupancode {
    display: inline-block;
    animation: moveUpDown 2s infinite ease-in-out;
  }
  
  @keyframes moveUpDown {
    0% {
      background-color:black;
    }
  
    100% {
        background-color: #FFA500;

    }
  }
  
  .coupancode:hover {
    background-color: #FF8C00;  
    border-color: #FF8C00;  
  }
  
.websitelogo{
    width: 73px;
    height: 54px;
}
.headerSearchRow{
    padding: 13px 0px;
    padding-left: 100px;
    padding-right: 150px;
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.menulogosearchdiv{
  width: 71%;
  display: flex;
  justify-content: space-between;
}
.searchbar{
    width: 456px;
    height: 39px;
    border-radius: 20px;
    background-color: #FFF4E1;
}

.pagenotelogo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 31px;
}

/* .cartlogo,.favlogo{
    position:relative;
   
} */
.search_icon_btn,.favlogo,.cartlogo,.profilelogo{
  cursor: pointer;
}

.search_icon_btn:hover,.favlogo:hover,.cartlogo:hover,.profilelogo:hover {
  fill: black; 
}
.icon {
  cursor: pointer;
  color: black; /* Default color */
  transition: color 0.3s ease;
}

.icon:hover {
  color: #000058; /* Hover color */
}

.favlogo:hover,.search_icon_btn:hover,.favlogo:hover,.cartlogo:hover,.profilelogo:hover {
  filter: brightness(0) saturate(100%) invert(27%) sepia(91%) saturate(7500%) hue-rotate(358deg) brightness(106%) contrast(102%);
}

.websitelogo{
  cursor: pointer;
}
.headerCategoryChooseRow{
    width:100%;
    overflow: scroll;
    display: flex;
    padding-left: 100px;
    justify-content: space-evenly;
    gap:5px;
    padding-bottom: 0%;
    margin-bottom: 0%;
    /* background-color: none !important; */
}

.headerCategoryChooseRow::-webkit-scrollbar{
    display: block;
}

.categoryproduct{
    /* min-width: 168px;  */
    word-wrap: nowrap; 
    /* card width on responsive need to do */
    height: fit-content;
    width: 150px;

}
.categoryproduct h5{
    font-size: 14px;
    font-weight: 400;
    line-height: 16.38px;
    text-align: left;
    cursor: pointer;
    width: 150px;

}
.categoryproductchoosen{
     width: 150px;
    /* min-width:188px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    background-color: white;
    gap: 5px;
    z-index: 10;
}
.categoryproductchoosen h5{
  width: 150px;

    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.38px;
    text-align: left;
    color: #FFC000;
    cursor: pointer;
    padding-left: 30px;
    padding-right: 5px;
    
}

.categoryproduct p{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    /* margin-bottom: 0.5rem !important; */
}
.productItem p{
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.productItem{
    padding: 0%;
    margin: 0%;
    padding-left: 30px;
    padding-right: 5px;
}

.headerIntroRow {
    width: 100%;
    position: absolute;
    bottom: 0; 
    padding: 10px 0px;
    background:#000058;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: center;
    letter-spacing: 1px;
    color: white;
    white-space: nowrap;
    box-sizing: border-box;
}

.headercontent{
    word-wrap: nowrap;
}
.headerIntroRow::-webkit-scrollbar{
    display: none;
}


.categoryItems {
    max-height: 0; 
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.5s ease, max-height 0.7s ease; 
   
  }

  .categoryItems:hover  .categoryItems.show{
 
   }

  .categoryItems.show {
    max-height: 500px; 
    opacity: 1; 
    padding: 10px 0;
  }
  
  
  .productItem {
    padding: 0;
    margin: 0;
    padding-left: 30px;
    padding-right: 5px;
    transition: background-color 0.9s ease; 
  }
  
  .productItem:hover {
    background-color:#000058;
    color: white;
  }
 
  .categoryproduct:hover .categoryItems {
    opacity: 1;
  }

  .scrollArrow ,.right{
    position: absolute;
    top:62%;
    right: 0%;
    font-size: 16px;
    color: white;
    padding: 2px 10px;
    background-color:black;
    display: none;
  }


.menubtn{
  display: none;
}
.search_icon_btn{
  display: none;
}
:where(.css-dev-only-do-not-override-apn68).ant-btn-variant-outlined, :where(.css-dev-only-do-not-override-apn68).ant-btn-variant-dashed {
  border-color:transparent;
  background:transparent;
}
:where(.css-dev-only-do-not-override-apn68).ant-drawer .ant-drawer-title {
  /* flex: 1; */
  /* margin: 0; */
  font-weight: 600;
  font-size: 20px;
  line-height: 22.41px;
  letter-spacing: 0.5px
  /* line-height: 1.5; */
}
:where(.css-dev-only-do-not-override-apn68).ant-menu .ant-menu-title-content {
  display: inline-flex;
  align-items: center;
  transition: color 0.3s;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  letter-spacing: 1px
}
:where(.css-dev-only-do-not-override-apn68).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-apn68).ant-menu-light>.ant-menu .ant-menu-item-selected {
  background-color: #000058;
  color: white;
  
}
.favlogo,.cartlogo {
  position: relative; /* Make sure the parent of the circle is positioned relative */
}

.count-circle,.countcart-circle {
  position: absolute;
  top: -10px; /* Adjust as needed to position it at the top-right */
  right: -10px; /* Adjust as needed to position it at the top-right */
  width: 20px;  /* Width of the circle */
  height: 20px; /* Height of the circle */
  border-radius: 50%; /* Makes the div circular */
  background-color: #000058; /* Blue background */
  color: white; /* White text */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px; /* Adjust the size of the number */
  font-weight: bold;
}

.customdrawer {

font-family: Gimlet Sans Variable;
font-size: 70px;
/* font-weight: 600;
line-height: 84px;
text-underline-position: from-font;
text-decoration-skip-ink: none; */
background-color: #FFF4E1 !important;


}
/* In your CSS file (e.g., App.css or a dedicated stylesheet) */
.ant-menu-submenu-title {
  background-color: #FFF4E1 !important;
}
.ant-menu.ant-menu-root {
  background-color: #FFF4E1 !important;
}
.headerCategoryChooseRow::-webkit-scrollbar{
  display: none !important;
}
  @media (max-width:1170px) {
    .headerCategoryChooseRow{
      display:none;
    }
    .menubtn{
      display:block;
    }
    .searchbar{
      display: none;
    }
    .search_icon_btn{
      display: block;
    }
    .headerContainer {
      height: 145px;   
  }
  }

@media (max-width:1360px) {
    .headerContainer{
        width:100vw;
        justify-content: space-around;
    }
    .pagenotelogo{
        gap: 10px !important;
    }
    .websitelogo{
        /* background-color: aqua !important; */
    }
    .searchbar{
        width: 40vw !important;

    }
    .headerSearchRow,.headerCategoryChooseRow{
        padding:0px 20px;
        padding-top: 13px;  
    }
   
}
@media (max-width: 767px) {
    .profilelogo .favlogo .cartlogo {
      width: 30px;
      height: 30px;
    }
    .website-logo {
        width: 150px;  /* Adjusted for mobile view */
        height: auto;
      }
      .searchbar{
        font-size: 14px; /* Smaller font size on mobile */
      }
  }
  
@media (max-width: 480px) {
    .profilelogo .favlogo .cartlogo {
      width: 24px;
      height: 24px;
   
    }
    .website-logo {
        width: 120px;  /* Adjusted for smaller mobile screens */
        height: auto;
      }
      .searchbar {
        margin: 0px 5px;
        width: 10%;     
        height: 35px;   
        font-size: 14px;
        background-color: rgb(51, 218, 115);
      }
  }

 
  @media (max-width: 2560px) and (min-width: 1660px) {
    .menulogosearchdiv {
        width: 58%;
    }
}

/* .categoryproduct h5:hover .header:hover .categoryproductchoosen {
  opacity: 1 !important;
  background-color: #FFC000; 
}

.categoryproductchoosen {
  background-color: red;
  opacity: 0 !important;
  transition: display 0.3s ease;
}

.categoryItems.show {
  opacity: 1;
} */
