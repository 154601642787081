.Footer14{
    width: 100%;
    height:fit-content;
    background: #000058;
}

.signupRow{
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-top: 51px;
    padding-bottom: 40px;
    border-bottom: 2px solid #FFC000;
}
.signupRow h4{
    font-family: Tenor Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.4px;
    text-align: left;
    color: #FFC000;
}
.SignUpContainer{
    width: 45vw;
    height: 35.7px;
    border: 1px solid #FFC000;
    background: #FFFFFF;
    display: flex;
}
.SignUpContainer input{
    width: 75%;
    outline: none;
    border: none;
    height: 100%;
}
.SignUpContainer button{
    width: 25%;
    height: 100%;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600 !important;
    line-height: 17.07px;
    text-align:center;
    background: #FFA500;
    color: white;
    outline: none;
    border: none;
}

/* .footerdetails{
    padding: 31px 101px;
    padding-bottom: 0%;
    width: 100%;
    flex-wrap: wrap;
    height: fit-content;
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #FFC000;
} */
.footerdetails {
    padding: 31px 101px;
    padding-bottom: 2%;
    width: 100%;
    flex-wrap: wrap;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #FFC000;
    row-gap: 20px;
}
.footercol{
    padding-right: 31.5px;
    border-right: 2px solid #FFA500;
}

.footercol:nth-last-child(1){
    border: none;
}
.footercol h4{
    color: #FFC000;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600 !important;
    line-height: 18.75px;
    text-align: center;
}
.footercol p{
    font-size: 12px;
    font-weight: 400;
    line-height: 24.63px;
    text-align: center;
    color: #FFFFFF;
    
}
.footercol5 h3{
    font-family: Roboto;
font-size: 24px;
font-weight: 600 !important;
line-height: 28.13px;
text-align: center;
color: #FFA500;
}
.footercol5{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footercol5button{
    background-color: #000058;
    color: #FFA500;
    outline: none;
    border: none;
    border-bottom: 1px solid #FFC000

}
.needhelp_section{
    display: flex;
    gap: 29px;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
    /* border-bottom: 2px solid white; */
    /* margin-bottom: 24px; */
    /* margin-left: 35px; */
}
.needhelp_section h6{
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600 !important;
    line-height: 14.63px;
    text-align: center;
    color: white;
}
.needhelp_section-content{
    display: flex;
    gap: 29px;
    justify-content: center;
    align-items: center;
}
.copyrightrow{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* background-color: #FFC000; */
}
.copyrightrow h5{
    padding-top: 18px ;
    padding-bottom: 18px;
    padding-right:78px;
    font-family: Montserrat;
font-size: 16px;
font-weight: 400;
line-height: 19.5px;
text-align: right;
color: #FFC000;

}
/* .Footer_Bottom_title {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    line-height: 200%;
    display: flex;
    align-items: center;
  }
.Footer_Bottom_title a{
    height: 100%;

    img{
      height: 100%;
    
    }
  } */
.copyrightrow h5 a{
    text-decoration: none;
    font-size: 16px;
font-weight: 400;
line-height: 19.5px;
text-align: right;
color: #FFC000;
}
.needhelp_section-content{
    gap: 50px;
}

@media (max-width:1142px) {
  
   .footerdetails {
    padding: 31px 5px;
    row-gap:20px ;
   }

    /* .footercol{
        padding-right:0px;
    } */
}

@media (max-width:855px) {
    .signupRow{
        padding: 25px;
         flex-direction: column;
    }
    .signupRow h4{
        font-family: Tenor Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 23.4px;
        text-align: center;
        color: #FFC000;
    }
    .SignUpContainer{
        width: 100%;
        height: 35.7px;
        border: 1px solid #FFC000;
        background: #FFFFFF;
        display: flex;
    }
    .copyrightrow{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .copyrightrow h5{
        padding:10px 0px;
        font-size: 14px;
        text-align: right;
    
    }
  
    .needhelp_section{
        display: flex;
        gap: 19px;
        align-items: center;
        justify-content:baseline;
        margin-bottom: 4px;
        border: none;
    }
    
}

@media (max-width:658px) {
    /* .coswanphonenumbersection{
        width:150px;
        overflow: hidden;
        background-color: #FFA500 !important;
    } */
    .coswanphonenumbersection h6{
          text-align: center;
    }
    .footerdetails {
        flex-direction: column;
        justify-content: flex-start;
        align-content: flex-start;
    }
      .footercol:nth-last-child(n){
        border: none;
    }
    .footercol p ,.footercol h4,.footercol5 h3 {
        text-align: left;
    }
    .needhelp_section {
        display: flex;
        gap: 19px;
        align-items: flex-start;
        justify-content: flex-start;
        border: none;
        margin-left: 0px;
    }
    .needhelp_section-content {
        display: flex;
        gap: 65px;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .footercol5 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .footercol5button{
        border: none !important;
    }
    .footerdetails {
        padding: 31px 29px;
        row-gap: 11px;
    }
    .copyrightrow h5 {
        padding: 10px 20px;
        font-size: 14px;
        text-align: right;
    }
    .copyrightrow {
        width: 100%;
        display: flex;
        justify-content:center;
        padding-bottom: 8px;
    }

    
}
@media (max-width: 438px) {
    .needhelp_section {
        display: flex;
        flex-direction: column;
        gap: 1px;
    }
    .needhelp_section h6{
        text-align: left !important;
    }
}
/* Add this to your footer14.css file */

/* Mobile view */
@media (max-width: 568px) {
    .Footer14 {
        padding: 10px;
    }
    .signupRow {
        padding:10px 0px;
        flex-direction: column;
    }
    .signupRow h4 {
        font-size: 16px;
        line-height: 20px;
    }

    .SignUpContainer button {
        font-size: 12px;
        line-height: 15px;
    }

    .footerdetails {
        padding: 20px 10px;
    }

    .footercol h4 {
        font-size: 14px;
        line-height: 18px;
    }

    .footercol p {
        font-size: 10px;
        line-height: 14px;
    }

    .needhelp_section h6 {
        font-size: 10px;
        line-height: 12px;
    }

    .footercol5 h3 {
        font-size: 20px;
        line-height: 24px;
    }

    .copyrightrow h5 {
        font-size: 12px;
        line-height: 16px;
    }
}

/* @media (max-width:390px) {
    .SignUpContainer input{
        width: 65%;
    }
    .SignUpContainer button{
        width: 35%;
        min-width: fit-content;
    }
    .copyrightrow h5{
        padding:10px 0px;
        font-size: 12px;
        text-align: center;
    
    }
} */

/* @media (max-width: 1220px) {
    .footerdetails {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        grid-gap: 0px;
    }
  .footercol{
      margin:0% ;
      padding: 40px 0px;
  }
    .footercol:nth-child(odd):last-child {
        grid-column: span 2;
        justify-self: center;
    }

 

    .footercol:nth-child(2n) {
        border-right: none;
        padding-left: 30%;
    }
    .footercol:nth-child(n) {
        border-bottom: 2px solid #FFA500;
    }


    .footerdetails .footercol:nth-last-child(1) {
        border-right: none; 
    }
}

@media (max-width:930px) {
    .footerdetails{
        padding: 20px;
    }
}

@media (max-width: 756px) {
    .signupRow{
        padding: 20px;
        flex-direction: column;
        height: fit-content;
    }
    .SignUpContainer{
        justify-self: flex-end;
        width: 85vw;
    }
    .footerdetails {
        width:100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 0%;
        padding: 0%;
    }
 
    .footercol {
        padding: 20px 0px;
        margin: 0%;
        padding:0%;
        border-right: none;
    }

    .footercol {
        border-bottom: 2px solid #FFA500; 
    }

    .footercol:nth-last-child(1) {
        border-bottom: none; 
    }
    .copyrightrow h5{
        padding-right: 20px !important;
    }
} */

@media (max-width: 658px) {
    .needhelp_section-content {
        display: flex;
        gap: 42px;
        justify-content: flex-start;
        align-items: flex-start;
    }
}


@media (min-width:1540px) {
 
    .footercol {
      padding-right:5% !important;
    }
  }
  
  @media (min-width:2040px) {
 
    .footercol {
      padding-right:6% !important;
    }
  }
  